import {
  Box,
  Stack,
  Text,
  Input,
  Button,
  AlertTitle,
  AlertDescription,
  Alert,
  AlertIcon,
  CloseButton,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { registration } from "../../services/Registration";

export default function Login() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
  });
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [error, setError] = useState([]);
  const [touchedFields, setTouchedFields] = useState({
    fullName: false,
    email: false,
    mobileNumber: false,
  });

  // eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    registration(formData)
      .then((response) => {
        if (response?.success) {
          setSuccessMsg(true);
          setError([]);
        } else {
          if (response?.errors) {
            // Handle the specific error message
            setError(response?.errors);
            setErrorMsg(true);
          } else {
            // Handle other error cases
            setError(["Registration failed. Please try again."]);
            setErrorMsg(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
      });
  };

  const handleBlur = (fieldName) => {
    if (!submitted) {
      setTouchedFields({ ...touchedFields, [fieldName]: true });
    }
  };

  // const showError = (fieldName) => {
  //   return touchedFields[fieldName] && formData[fieldName].trim() === "";
  // };

  useEffect(() => {
    setFormData({ fullName: "", email: "", mobileNumber: "" });
  }, [successMsg]);
  return (
    <>
      <Stack
        bg={"#e1e1e1"}
        rounded={"sm"}
        // bgGradient="linear(to-r, blue.400,blue.400)"
        boxShadow="md"
        p={{ base: 4, sm: 6, md: 8 }}
        spacing={{ base: 4 }}
        maxW={{ lg: "lg" }}
      >
        <Stack spacing={4}>
          <Text
            paddingX={8}
            color={"gray.800"}
            lineHeight={1.5}
            fontWeight={"600"}
            // fontFamily={"sans-serif"}
            textAlign={"center"}
            fontSize={{ base: "2xl", sm: "3xl", md: "3xl" }}
          >
            Tuli Research Centre for India Studies!
          </Text>
        </Stack>
        {successMsg && (
          <Alert status="success">
            <AlertIcon />
            <Box>
              <AlertTitle>Thank You!</AlertTitle>
              <AlertDescription>
                You've successfully registered for the event. We look forward to
                seeing you there!
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={() => setSuccessMsg(false)}
            />
          </Alert>
        )}
        <Box as={"form"} mt={1}>
          <Stack spacing={4}>
            {errorMsg && error && (
              <Alert status="error" justifyContent={"space-between"}>
                {/* <AlertIcon /> */}
                <Box>
                  <UnorderedList>
                    {error.map((errorMessage, index) => (
                      <ListItem key={index}>{errorMessage}</ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={() => setErrorMsg(false)}
                />
              </Alert>
            )}
            <Input
              placeholder="Full Name"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              onBlur={() => handleBlur("fullName")}
              required
            />
            {/* {showError("fullName") && (
              <span style={{ color: "red" }}>
                Please provide your full name
              </span>
            )} */}
            <Input
              placeholder="Email Address"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={() => handleBlur("email")}
              required
            />
            {/* {error && (
              <Text color={"red"}>
                {error}
                {"*"}
              </Text>
            )} */}
            {/* {showError("email") && (
              <span style={{ color: "red" }}>Please provide a valid email</span>
            )} */}
            <Input
              placeholder="Mobile Number"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
              maxLength={10}
              type="tel" // Changed type to 'tel' for mobile number
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              onBlur={() => handleBlur("mobileNumber")}
              required
            />
            {/* {showError("mobileNumber") && (
              <span style={{ color: "red" }}>
                Please provide a mobile number
              </span>
            )} */}
          </Stack>
          <Button
            textStyle={"primary"}
            mt={8}
            w={"full"}
            bgGradient="linear(to-r, blue.400,blue.400)"
            color={"white"}
            onClick={handleSubmit}
            _hover={{
              // bgGradient: "linear(to-r, red.400,pink.400)",
              bgGradient: "linear(to-r, blue.400,blue.400)",
              boxShadow: "xl",
            }}
          >
            Register
          </Button>
        </Box>
      </Stack>
    </>
  );
}
