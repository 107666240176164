import {
  Box,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Facebook from "../UI/facebook.png";
import LinkedIn from "../UI/linkedin.png";
import Instagram from "../UI/instagram.png";

export default function SmallCentered() {
  return (
    <Box
      // bg={useColorModeValue("gray.00", "gray.300")}
      bg="#3c3c3c"
      // color={useColorModeValue("gray.700", "gray.200")}
      backgroundImage="linear-gradient(#4c4c4c, #000000)"
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={0}
        justify={"center"}
        align={"center"}
      >
        <Stack gap={"0.0rem"} py={8}>
          <Text
            color={"white"}
            fontFamily={"sans-serif"}
            lineHeight={1.3}
            fontWeight={"600"}
            fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
            textAlign={"center"}
          >
            Exhibition Continues
            <Text fontFamily={"sans-serif"} as={"span"} color={"#ffd335"}>
              {" "}
              until 30th March 2024
            </Text>{" "}
            <Text
              textAlign={"center"}
              fontFamily={"sans-serif"}
              fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
            >
              ICC Galley,
            </Text>
          </Text>
          <Stack
            gap={"0.0rem"}
            alignItems={"center"}
            direction={"column"}
            spacing={2}
          >
            <Text
              fontFamily={"sans-serif"}
              color={"white"}
              fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "3xl" }}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Kamaladevi Chattopadhyay Block
            </Text>
            <Text
              fontFamily={"sans-serif"}
              color={"white"}
              fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "3xl" }}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Max Mueller Marg, New Delhi 110003
            </Text>
            <Text
              as={"span"}
              fontFamily={"sans-serif"}
              // bgGradient="linear(to-r, yellow.400,yellow.400)"
              // bgClip="text"
              color={"#ffd335"}
              lineHeight={1.1}
              fontWeight={"600"}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
            >
              11am to 7pm daily
            </Text>
          </Stack>
        </Stack>
        <Stack pb={4} direction={"row"} spacing={16}>
          <Box width={"42px"} height={"100%"}>
            <Image
              src={Facebook}
              width={"100%"}
              height={"inherit"}
              alt={"Facebook"}
            />
          </Box>
          <Box width={"42px"} height={"100%"}>
            <Image
              src={LinkedIn}
              width={"100%"}
              height={"inherit"}
              alt={"LinkedIn"}
            />
          </Box>
          <Box width={"42px"} height={"100%"}>
            <Image
              src={Instagram}
              width={"100%"}
              height={"inherit"}
              alt={"Instagram"}
            />
          </Box>
        </Stack>
        <Stack gap={"0.1rem"}>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            © Image Credits
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            Raghu Rai | Chawri Bazar Traffic, Delhi | Ilford-Multigrade RC–
            Pearl Warmtone Paper, 1966
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            Raj Kapoor in Shree 420 | 33 rpm LP Gramophone Records, 1955
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            Charlie Chaplin in A Dog’s Life | Vintage Lobby Card for Charlie
            Chaplin, 1918
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            Chittaprosad | Quit Asia | Brush & ink on paper, 1947
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            The K.G. Subramanyan. Seagull Publication Book Covers
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            K. Laxma Goud | Forest Series | Etching on paper, 1976
          </Text>
          <Text fontSize={"13px"} textAlign={"center"} color={"white"}>
            Kranti | Twelve-sheeter Poster : Offset print on paper, 1981
          </Text>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.500", "gray.500")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "center" }}
          align={{ base: "center", md: "center" }}
        >
          <Text fontSize={"13px"} color={"white"}>
            © 2024 Tuli Research center for Indias Studies
          </Text>
        </Container>
      </Box>
    </Box>
  );
}
