import {
  Box,
  Image,
  Stack,
  Text,
  Container,
  SimpleGrid,
  useBreakpointValue,
  Icon,
} from "@chakra-ui/react";
import Logo from "../../TRC-logo.svg";
import Image1 from "../UI/img.jpg";
import SmallCentered from "./Footer";
import Login from "./Login";
import ArticleList from "./blog";

const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({
        base: "-webkit-fill-available",
        md: "45vw",
        lg: "80vw",
      })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: -1 })}
      height="100%"
      viewBox="300 0 328 660"
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" opacity={0.1} />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" opacity={0.1} />
      <circle cy="291" r="139" fill="#ED64A6" opacity={0.1} />
      <circle cx="180.5" cy="189.5" r="101.5" fill="#ED8936" opacity={0.1} />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" opacity={0.1} />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" opacity={0.1} />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" opacity={0.1} />
    </Icon>
  );
};

export default function TuliIndiaOrg() {
  return (
    <Box position={"relative"} height={"100vh"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        // gridGap={"1.5rem"}
        justifyItems={"center"}
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, lg: 30 }}
        pt={{ base: 4, sm: 4, lg: 10 }}
        px={{ base: 0, sm: 4, lg: 0 }}
        // pb={{ base: 10, sm: 10, lg: 10 }}
      >
        <Stack
          alignItems={{ base: "center", md: "center" }}
          flex={"1"}
          spacing={{ base: 4, md: 4 }}
        >
          <Image src={Logo} width="auto" height={"150px"} />
          <Text
            fontFamily={"sans-serif"}
            fontSize={{ base: "18px", sm: "18px", md: "24px", lg: "24px" }}
            fontWeight={"500"}
          >
            THE INAUGURAL EXHIBITION SERIES
          </Text>
          <Text
            fontFamily={"sans-serif"}
            lineHeight={1.1}
            fontWeight={"700"}
            textAlign={"center"}
            fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "5xl" }}
          >
            Self-Discovery via Rediscovering India
          </Text>
        </Stack>

        <Stack width={"100%"}>
          <Box width={"100%"}>
            <Image
              src={Image1}
              width={"100%"}
              objectFit={"cover"}
              height={"auto"}
            />
          </Box>
        </Stack>
      </Container>

      <Container
        as={SimpleGrid}
        gridGap={{ base: "1.8rem", md: "0.5rem" }}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 2 }}
        background={"#f0f8ff"}
        justifyItems={"center"}
        py={{ base: 8, sm: 10, lg: 10 }}
      >
        <Stack
          paddingY={{ base: 0, md: 4 }}
          gap={"0.0rem"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            fontWeight={"700"}
            lineHeight={1.4}
            textAlign={"center"}
            fontFamily={"sans-serif"}
            color={"#d92323"}
            fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "4xl" }}
          >
            Opening Preview
            <Text as={"span"} color={"#000000"}>
              {" "}
              6pm
            </Text>
            <Text color={"#000000"}>Friday 15th March 2024</Text>
            <Text
              color={"#000000"}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "4xl" }}
            >
              ICC Galley
            </Text>
          </Text>

          <Stack
            gap={"0.0rem"}
            alignItems={"center"}
            direction={"column"}
            spacing={2}
          >
            <Text
              fontFamily={"sans-serif"}
              fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
              fontWeight={"500"}
            >
              Kamaladevi Chattopadhyay Block
            </Text>
            <Text
              fontFamily={"sans-serif"}
              fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
              fontWeight={"500"}
            >
              Max Mueller Marg, New Delhi 110003
            </Text>
          </Stack>
        </Stack>
        <Login />
      </Container>
      <Stack>
        <ArticleList />
      </Stack>

      <Stack>
        <SmallCentered />
      </Stack>
      <Blur
        position={"absolute"}
        top={-10}
        left={20}
        style={{ filter: "blur(120px)" }}
      />
    </Box>
  );
}
