import {
  Box,
  Image,
  Text,
  Stack,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import Image1 from "../UI/img1.jpg";
import Image2 from "../UI/img2.jpg";
import Image4 from "../UI/img3.jpg";
import Image5 from "../UI/img4.jpg";
import Image6 from "../UI/img5.jpg";
import ImgIcon from "../UI/qicon.png";

const ArticleList = () => {
  return (
    <Container maxW={"7xl"} p="0">
      <Stack>
        <Box width="100%">
          <Image src={Image2} width={"100%"} height={"100%"} />
        </Box>
        <Box>
          <Text
            as="p"
            px={{ base: "20px", md: "60px" }}
            py={{ base: "20px", md: "30px" }}
            textStyle={"primary"}
            color={"#000000"}
            fontSize={{ base: "1xl", sm: "1xl", md: "1xl", lg: "2xl" }}
          >
            The exhibition{" "}
            <strong>‘Self Discovery via Rediscovering India’,</strong> staged in
            collaboration with the India International Centre, presents the
            vision and select highlights of the vast domain of India Studies
            through original and digitised artworks, artefacts, archives, and
            memorabilia from the{" "}
            <strong>Tuli Research Centre for India Studies,</strong> primarily
            focusing on the fields of modern and contemporary art, Indian and
            world cinema, photography, the popular arts, crafts and
            architectural heritage.
          </Text>
        </Box>
      </Stack>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        gap={0}
        flexDirection={{ base: "column", sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Box display="flex" flex="1" position="relative" alignItems="center">
          <Box
            width={{ base: "100%", sm: "100%" }}
            zIndex="2"
            // marginX={{ base: "0", sm: "5%" }}
            // marginY="5%"
          >
            <Box textDecoration="none" _hover={{ textDecoration: "none" }}>
              <Image
                // borderRadius="lg"
                src={Image5}
                alt=""
                objectFit="contain"
              />
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                "radial(orange.600 1px, transparent 1px)",
                "radial(orange.300 1px, transparent 1px)"
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1.3"
          bg="#4a4a4a"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            as="p"
            color={"white"}
            p={{ base: "20px", md: "80px" }}
            textStyle={"primary"}
            fontSize={{ base: "1xl", sm: "1xl", md: "1xl", lg: "2xl" }}
          >
            The Archives and Library which are the backbone of this Research
            Centre will be introduced through a series of exhibitions, seminars,
            and conferences over the next three months, opening out a pioneering
            conceptual framework and curriculum for the first three-year degree
            programme for Contemporary India Studies in the world.
          </Text>
          {/* <BlogAuthor name="John Doe" date={new Date("2021-04-06T19:01:27Z")} /> */}
        </Box>
      </Box>
      <Stack gap={"0.0rem"} alignItems={"center"} pt={4} pb={8}>
        <Box width="max-content" height={"64px"}>
          <Image src={ImgIcon} width={"100%"} height={"100%"} />
        </Box>
        <Text
          pt="4"
          textStyle={"primary"}
          px={{ base: "20px", md: "190px" }}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
          textAlign={"center"}
        >
          The exhibition draws on material belonging to a vision that posits the
          contemporary using the mid-nineteenth century as its starting point
        </Text>
      </Stack>
      <Stack>
        <Box width="100%">
          <Image src={Image6} width={"100%"} height={"100%"} />
        </Box>
      </Stack>

      <Stack gap={"0.0rem"}>
        <Box
          borderX={"1px"}
          borderColor={"#e8e8e8"}
          p={{ base: 6, md: 12 }}
          width="100%"
        >
          <Image src={Image4} width={"100%"} height={"100%"} />
        </Box>
        <Text
          textStyle={"primary"}
          p={{ base: "20px", md: "70px" }}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
          textAlign={"left"}
          color={"#000000"}
          bg={"#d9dddc"}
        >
          After a three-decade journey dedicated to the building of cultural
          institutions and their infrastructure in India, Neville Tuli publicly
          shares a unique knowledge and learning-base rooted in the arts,
          available online and offline to all in August 2024 with the launch of
          the <strong>Tuli Research Centre for India Studies</strong> and{" "}
          <strong>www.tuliresearchcentre.org</strong>
        </Text>
      </Stack>
      <Stack>
        <Box width="100%">
          <Image src={Image1} width={"100%"} height={"100%"} />
        </Box>
      </Stack>
    </Container>
  );
};

export default ArticleList;
