const API_BASE_URL = process.env.REACT_APP_EVENT_INVITATION_API_BASE_URL;

export const registration = (formData) => {
  return fetch(`${API_BASE_URL}/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error submitting data:", error);
    });
};
