// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";
import "../../src/index.css";
import "../fonts/SegoeUI.ttf";
import "../fonts/SegoeUIBold.ttf";

const theme = extendTheme({
  fonts: {
    heading: `'Segoe UI'`,
    body: `'Raleway'`,
  },
  textStyles: {
    primary: {
      fontFamily: "Raleway",
    },
    secondary: {
      fontFamily: `'Segoe UI'`,
    },
    tertiary: {
      fontFamily: `'Segoe UI Bold'`,
    },
  },
  components: {
    MultiSelect: MultiSelectTheme,
    Button: {
      baseStyle: {
        _focus: { outline: "none" },
        _active: { outline: "none" },
        _hover: { outline: "none" },
      },
    },
    MenuItem: {
      baseStyle: {
        _focus: { outline: "none" },
        _active: { outline: "none" },
        _hover: { outline: "none" },
      },
    },
  },
});

export default theme;
